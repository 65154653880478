exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GoogleMap_GoogleMap__SuDwg {\n    display: block;\n    margin: 2rem 0 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"GoogleMap": "GoogleMap_GoogleMap__SuDwg"
};