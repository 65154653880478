var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SideDrawer_SideDrawer__2zgUK {\n    position: fixed;\n    width: 300px;\n    max-width: 70%;\n    height: 100%;\n    left: 0;\n    top: 0;\n    z-index: 200;\n    background: #1f2222 url(" + escape(require("../../../assets/fena-bg.svg")) + ") repeat;\n    padding: 32px 16px;\n    box-sizing: border-box;\n    transition: -webkit-transform 0.3s ease-out;\n    transition: transform 0.3s ease-out;\n    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;\n}\n\n.SideDrawer_Open__1Qe74 {\n    -webkit-transform: translateX(0);\n            transform: translateX(0);\n}\n\n.SideDrawer_Close__RQ-sF {\n    -webkit-transform: translateX(-100%);\n            transform: translateX(-100%);\n}\n\n.SideDrawer_Logo__1RlSm {\n    text-align: center;\n    margin-bottom: 2rem;\n}\n\n.SideDrawer_Logo__1RlSm img {\n    width: 80%;\n}\n\n.SideDrawer_Contact__2jsKf {\n    padding: 0 15px;\n}\n\n.SideDrawer_Contact__2jsKf h3 {\n    font-weight: 700;\n}\n\n.SideDrawer_Contact__2jsKf p {\n    margin: 0;\n    color: #fff;\n}\n", ""]);

// exports
exports.locals = {
	"SideDrawer": "SideDrawer_SideDrawer__2zgUK",
	"Open": "SideDrawer_Open__1Qe74",
	"Close": "SideDrawer_Close__RQ-sF",
	"Logo": "SideDrawer_Logo__1RlSm",
	"Contact": "SideDrawer_Contact__2jsKf"
};