import React from 'react';
import classes from './GoogleMap.module.css';

const googleMap = (props) => (
    <iframe
        className={classes.GoogleMap}
        title="google_map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.7993654725806!2d21.962643849224254!3d43.004770122277655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47558308dadfc747%3A0xfd3ce6bb5d4b3a37!2sFena%20doo%20-%20servis%20i%20radionica%20za%20tahografe!5e0!3m2!1ssr!2srs!4v1668375150707!5m2!1ssr!2srs"
        width="100%"
        height="500"
        frameBorder="0"
        allowFullScreen>
    </iframe>
  // <iframe 
  //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3469.7993654725806!2d21.962643849224254!3d43.004770122277655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47558308dadfc747%3A0xfd3ce6bb5d4b3a37!2sFena%20doo%20-%20servis%20i%20radionica%20za%20tahografe!5e0!3m2!1ssr!2srs!4v1668375150707!5m2!1ssr!2srs" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
);

export default googleMap;
