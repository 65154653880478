exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Content_Content__2Z4Fq {\n    margin: 2.5rem auto;\n    max-width: 1280px;\n}\n\n.Content_Row1__1cMDH,\n.Content_Row2__1GFPa {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.Content_Row2__1GFPa {\n    flex-direction: row-reverse;\n}\n\n.Content_Dtco3__28FrI,\n.Content_Mtco1324__3KSv3 {\n    flex-basis: 230px;\n    min-width: 0;\n    padding: 2rem;\n    flex-grow: 0.4;\n    margin: auto;\n}\n\n.Content_Dtco3__28FrI svg,\n.Content_Mtco1324__3KSv3 svg {\n    width: 100%;\n    height: 100%;\n}\n\n.Content_Tahografi__1g8Gl,\n.Content_ONama__11hkp {\n    flex-basis: 230px;\n    flex-grow: 4;\n    padding: 2rem;\n}\n", ""]);

// exports
exports.locals = {
	"Content": "Content_Content__2Z4Fq",
	"Row1": "Content_Row1__1cMDH",
	"Row2": "Content_Row2__1GFPa",
	"Dtco3": "Content_Dtco3__28FrI",
	"Mtco1324": "Content_Mtco1324__3KSv3",
	"Tahografi": "Content_Tahografi__1g8Gl",
	"ONama": "Content_ONama__11hkp"
};