exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Backdrop_Backdrop__2z2rU {\n    width: 100%;\n    height: 100%;\n    position: fixed;\n    z-index: 100;\n    left: 0;\n    top: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n}\n", ""]);

// exports
exports.locals = {
	"Backdrop": "Backdrop_Backdrop__2z2rU"
};