exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Location_LocationInfo__xcZJs {\n    max-width: 800px;\n    margin: auto;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.Location_LocationInfo__xcZJs i {\n    flex-basis: 100px;\n    height: 100px;\n    align-self: center;\n    flex-grow: 1;\n    padding: 2rem 2rem 0 2rem;\n    text-align: center;\n}\n\n.Location_Info__21qK1 {\n    flex-basis: 350px;\n    flex-grow: 6;\n    padding: 2rem;\n}\n", ""]);

// exports
exports.locals = {
	"LocationInfo": "Location_LocationInfo__xcZJs",
	"Info": "Location_Info__21qK1"
};