import React from 'react';
import classes from './Location.module.css';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import GoogleMap from './GoogleMap/GoogleMap';

const location = (props) => (
	<div className={classes.Location}>
        <div className={classes.LocationInfo}>
            <MaterialIcon
                icon="room"
                size='100'
                color={colorPalette.red._800} />
            <div className={classes.Info}>
                <h2>Gde se nalazimo?</h2>
                <p>Servis i radionica za tahografe - Fena DOO Leskovac se nalazi na ulazu u Leskovac, pored Auto-moto saveza Srbije (AMSS). (Ranije se nalazila na Vlasotinačkoj petlji, preseljena je od juna 2021. godine) </p>
                <p><strong>Lako dostupna za prilaz sa bilo kog isključenja sa auto-puta za Leskovac.</strong></p>
            </div>
        </div>
        <GoogleMap />
    </div>
);

export default location;
