var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo_LogoFull__2yGIV {\n  background: url(" + escape(require("../../assets/fena-bg.svg")) + ") repeat;\n  margin: 20px auto;\n  text-align: center;\n  -webkit-animation: Logo_fadein__1F5Tm 4s;\n          animation: Logo_fadein__1F5Tm 4s;\n}\n\n.Logo_LogoFull__2yGIV h1 {\n  color: #387d7a;\n  font-weight: 400;\n  font-size: 2.2rem;\n  margin: 0 0 1rem 0;\n  line-height: 100%;\n}\n\n.Logo_LogoFull__2yGIV {\n  width: 100%;\n}\n\n/* u slučaju da se u nekim browserima svg renderuje kao img */\n.Logo_LogoFull__2yGIV img {\n  width: 100%;\n  max-width: 500px;\n}\n\n.Logo_Logo__2E70W svg {\n  width: 100%;\n  height: 100%;\n  max-width: 500px;\n}\n\n@-webkit-keyframes Logo_fadein__1F5Tm {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n@keyframes Logo_fadein__1F5Tm {\n  0% {\n    opacity: 0;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"LogoFull": "Logo_LogoFull__2yGIV",
	"fadein": "Logo_fadein__1F5Tm",
	"Logo": "Logo_Logo__2E70W"
};