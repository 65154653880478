var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Footer_Footer__34xfh {\n  background: #1f2222 url(" + escape(require("../../assets/fena-bg.svg")) + ") repeat;\n  color: #fff;\n}\n\n.Footer_Content__1E8f2 {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-evenly;\n  max-width: 1280px;\n  margin: 0 auto;\n}\n\n.Footer_Contact__2GUEg,\n.Footer_Resources__3KH-4 {\n  flex-basis: 270px;\n  padding: 0 2rem;\n}\n\n.Footer_Copyright__64qZo {\n  background: rgba(18, 20, 20, 0.2);\n  height: 50px;\n  line-height: 50px;\n  padding-left: 2rem;\n  margin-top: 2rem;\n  text-align: center;\n}\n\n.Footer_Footer__34xfh p {\n  margin: 0;\n}\n\n.Footer_Footer__34xfh a {\n  display: block;\n  text-decoration: none;\n  color: #cc1d3a;\n}\n\n.Footer_Logo__3Gi7e {\n  display: block;\n}\n\n.Footer_Logo__3Gi7e svg {\n  width: 100%;\n  height: 100%;\n  margin-top: 2rem;\n}\n\n/* // u slučaju da se u nekim browserima svg renderuje kao img */\n.Footer_Resources__3KH-4 img {\n  margin-top: 2rem;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"Footer": "Footer_Footer__34xfh",
	"Content": "Footer_Content__1E8f2",
	"Contact": "Footer_Contact__2GUEg",
	"Resources": "Footer_Resources__3KH-4",
	"Copyright": "Footer_Copyright__64qZo",
	"Logo": "Footer_Logo__3Gi7e"
};