exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NavigationItems_NavigationItems__36DbC {\n    height: 100%;\n    padding: 0;\n    margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"NavigationItems": "NavigationItems_NavigationItems__36DbC"
};