var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Picture_Picture__2aLbS {\n    min-height: 22rem;\n    background-image: url(" + escape(require("../../assets/autoput.jpg")) + ");\n    background-size: cover;\n    background-position: center;\n}\n", ""]);

// exports
exports.locals = {
	"Picture": "Picture_Picture__2aLbS"
};