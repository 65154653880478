exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NavigationItem_NavigationItem__3_Soa {\n    box-sizing: border-box;\n    display: inline-block;\n    height: 100%;\n    padding: 0 1rem;\n    list-style: none;\n}\n\n.NavigationItem_NavigationItem__3_Soa a {\n    color: #ffc857;\n    text-decoration: none;\n}\n\n.NavigationItem_NavigationItem__3_Soa:hover,\n .NavigationItem_NavigationItem__3_Soa:active {\n    cursor: pointer;\n    background-color: #262c2c;\n}\n\n@media (max-width: 1000px) {\n    .NavigationItem_NavigationItem__3_Soa {\n        width: 100%;\n        padding: 0;\n        border-radius: 10px;\n    }\n\n    .NavigationItem_NavigationItem__3_Soa a {\n        font-size: 18px;\n        display: block;\n        height: 100%;\n        padding: 15px;\n        color: #cc1d3a;\n        text-shadow: 1px 1px 1px #000;\n    }\n\n    .NavigationItem_NavigationItem__3_Soa:hover,\n    .NavigationItem_NavigationItem__3_Soa:active {\n        background-color: rgba(106, 175, 156, 0.2);\n    }\n}\n", ""]);

// exports
exports.locals = {
	"NavigationItem": "NavigationItem_NavigationItem__3_Soa"
};