exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DrawerToggle_Icon__3e-ZD {\n    display: none;\n}\n\n@media (max-width: 1000px) {\n    .DrawerToggle_Icon__3e-ZD {\n        display: inline-block;\n        position: absolute;\n        top: 14px;\n        left: 14px;\n        height: 36px;\n    }\n\n    .DrawerToggle_Icon__3e-ZD:hover {\n        cursor: pointer;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"Icon": "DrawerToggle_Icon__3e-ZD"
};