exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Header_Header__2438o {\n    height: 64px;\n    background-color: #373d3d;\n    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2);\n    line-height: 64px;\n}\n\n.Header_Content__1KefZ {\n    margin: auto;\n    max-width: 1280px;\n    display: flex;\n    justify-content: space-between;\n    height: 100%;\n}\n\n.Header_Home__1dKyW {\n    flex-basis: 50%;\n    padding: 0 2rem;\n}\n\n.Header_Home__1dKyW a {\n    color: #fff;\n    font-size: 2rem;\n    text-decoration: none;\n}\n\n.Header_Navigation__1mkGz {\n    text-align: right;\n    padding: 0 2rem;\n}\n\n.Header_Icon__2kYWR {\n    display: none;\n}\n\n@media (max-width: 1000px) {\n    .Header_Home__1dKyW {\n        flex-basis: 100%;\n        text-align: center;\n        vertical-align: middle;\n    }\n\n    .Header_Navigation__1mkGz {\n        display: none;\n    }\n}\n", ""]);

// exports
exports.locals = {
	"Header": "Header_Header__2438o",
	"Content": "Header_Content__1KefZ",
	"Home": "Header_Home__1dKyW",
	"Navigation": "Header_Navigation__1mkGz",
	"Icon": "Header_Icon__2kYWR"
};